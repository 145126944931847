import { __ } from '@wordpress/i18n';
import { InnerBlocks } from '@wordpress/block-editor';

export default function Save(props){

    const { attributes} = props;
    const { accordionLabel, icons, textAlign, bg_color, text_color, font_size } = attributes;
    

    return(
        <div className={props.className}>
            <div className='title-content'>
                <h3 style={{color:text_color, background:bg_color, fontSize:font_size }} className={"container-accordion " + textAlign}
                    dangerouslySetInnerHTML={{__html: accordionLabel}}
                >
                </h3>
                <div className="button-show">
                    <div className="botton-show__container">
                        <span className={icons}></span>
                    </div>
                </div>
            </div>
            <div className='body-accordion'>
                <InnerBlocks.Content />
            </div>
        </div>

    );

    
}
