import { __ } from '@wordpress/i18n';
import { InspectorControls, InnerBlocks, RichText, AlignmentToolbar, BlockControls, ColorPalette} from '@wordpress/block-editor';
import { Panel, PanelBody, FontSizePicker } from '@wordpress/components'
import { useState } from '@wordpress/element';
import { more } from '@wordpress/icons';

export default function Edit(props){

    const { attributes, setAttributes} = props;
    const { accordionLabel, icons, text_color, textAlign, showContent, showBtn, bg_color, font_size } = attributes;
    const fallbackFontSize = 20;
    const [ fontSize, setFontSize ] = useState( 20 );

    const fontSizes = [
        {
          size: "13px",
          name: "Small",
          slug: "small"
        },
        {
          size: "20px",
          name: "Medium",
          slug: "medium"
        },
        {
          size: "36px",
          name: "Large",
          slug: "large"
        },
        {
          size: "42px",
          name: "Extra Large",
          slug: "x-large"
        }
      ]

    return(
        <>
            <InspectorControls>
                <Panel>
                    <PanelBody title="Editar titulo" icon={ more } initialOpen={ true }>
                        <fieldset>
                            <label>{ __( 'Color de cabecera' ) }</label>
                            <ColorPalette 
                                value={text_color}
                                onChange={ (newValue) => setAttributes({text_color: newValue}) }
                            />
                        </fieldset>
                        <fieldset>
                            <label>{ __( 'Color de cuerpo' ) }</label>
                            <ColorPalette 
                                value={bg_color}
                                onChange={ (newbg_color) => setAttributes({bg_color: newbg_color}) }
                            />
                        </fieldset>

                        <FontSizePicker
                            __nextHasNoMarginBottom
                            fontSizes={ fontSizes }
                            value={ fontSize }
                            fallbackFontSize={ fallbackFontSize }
                            onChange={ ( newFontSize ) => {
                                setFontSize( newFontSize );
                                setAttributes({font_size : newFontSize});
                            } }
                        />
                    </PanelBody>
                </Panel>
            </InspectorControls>

        <BlockControls>
            <AlignmentToolbar
                value={ textAlign }
                onChange={ ( nextAlign ) => {
                    setAttributes( { textAlign: nextAlign } );
                } }
            />
        </BlockControls>
        <div className={props.className}>
            <div className='title-content'>
                <RichText 
                    style={{color:text_color, background:bg_color, fontSize:fontSize}}               
                    className={textAlign}
                    tagName = "h3"
                    value={accordionLabel}
                    allowedFormats={ [ 'core/bold', 'core/italic',  'core/link', 'core/strikethrough', 'core/image'] }
                    onChange={ ( accordionLabel ) => setAttributes( { accordionLabel } ) }
                    placeholder={ __( 'Texto cabecera' ) }

                />
                <div className={ "button-show " + showBtn}>
                    <div className="botton-show__container">
                        <span class={icons}></span>
                    </div>
                </div>
            </div>
            <div className={showContent + ' body-accordion'}>
                <InnerBlocks/>
            </div>
        </div>  
        </>    
    )
}
