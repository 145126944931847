import {registerBlockType} from '@wordpress/blocks';
import {__} from '@wordpress/i18n';

import Edit from "./edit";
import Save from "./save";

registerBlockType("plm/accordion-single",{
    title: __("Titulo de accordion"),
    description: __('content elements for accordion'),
    icon: "menu",
    parent:['plm/accordion'],
    attributes: {
        accordionLabel:{
            type: 'string',
        },
        icons:{
            type: 'string',
            default: 'dashicons dashicons-arrow-down-alt2',
        },
        textAlign:{
            type:"string", 
            default:"left"
        },
        text_color:{
            type:"string", 
            default:"var(--ast-global-color-4)"
        },
        bg_color:{
            type:"string", 
            default:""
        },
        showContent:{
            type:"string", 
            default:"body-accordion--show"
        },
        showBtn:{
            type:"string", 
            default:"button-show--rotate"
        },
        font_size:{
            type:"string", 
        }
    },
    edit: Edit,
    save: Save
})