import { __ } from '@wordpress/i18n';
import { useBlockProps, InnerBlocks, InspectorControls } from '@wordpress/block-editor';
import{Panel, PanelBody, TextControl } from "@wordpress/components"
import { useRef, useEffect} from "@wordpress/element";

export default function Edit(props){

    const { attributes, setAttributes, clientId} = props;
    const { blockId } = attributes;

    if ( ! blockId ) {
        setAttributes( { blockId: clientId } );
    }
    

    return(
        <>    
            <div
                { ...useBlockProps({
                    className:`class-id-${clientId} content-accordion`
                })}
            >   
                <div className="content-accordion">
                    <InnerBlocks allowedBlocks={ ['core/single-accordion']} />
                </div>
            </div>
            
        </>
    )
}

