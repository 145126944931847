import { __ } from '@wordpress/i18n';
import { InnerBlocks } from '@wordpress/block-editor';
import "./index.css";

export default function save(props){
    const { attributes} = props;
    const { blockId } = attributes;

       
    return(
        <div id={blockId} className='init-accordion'>
            <div class="title-accordion">
                <InnerBlocks.Content />
            </div>
        </div>
    );

    
}

