function accordionEvents(){
    const accordionBtn = document.querySelectorAll('.title-content');
    
    accordionBtn.forEach(element=>{
        element.addEventListener('click', event =>{

            const btnShow = element.querySelector('.button-show');
            const bodyAccordion = element.nextSibling;

            bodyAccordion.classList.toggle('body-accordion--show');
            btnShow.classList.toggle('button-show--rotate');

        })
    })

}

window.addEventListener("load", function(event){
    accordionEvents();
});

